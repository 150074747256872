@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 900px) {
  .pcnone {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #333333;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  animation: fadeInAnime 1.5s ease 0s;
}

.inner {
  margin: 0 auto;
  max-width: 1420px;
  width: 100%;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.inner2 {
  max-width: 1642px;
  margin: auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  padding-bottom: 150px;
  position: relative;
}

#contents_wrap .lineDeco {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
  z-index: -1;
}

#contents {
  width: 100%;
  max-width: 1420px;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 0;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav.panelactive {
  background-color: #fff;
}

#g-nav.panelactive .fix_contact {
  margin-top: 30px;
}

.navR {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
}

#g-nav {
  position: relative;
}

@media only screen and (max-width: 900px) {
  #g-nav {
    display: flex;
  }
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav {
    display: block;
    width: 80%;
    margin: auto;
  }
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li a {
  text-align: center;
  padding: 10px;
  display: block;
  position: relative;
  color: #333333;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  #g-nav .mainNav li a {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.8rem;
    border-bottom: 1px solid #DFECF6;
    text-align: left;
    position: relative;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    color: #0a69bd;
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

#toggle .is-open {
  display: block;
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (max-width: 900px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.dropdown li:last-of-type a {
  border-bottom: none;
}

.dropdown li a {
  background-color: #0a69bd;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #fff;
  padding: 15px !important;
}

@media only screen and (max-width: 900px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #333333 !important;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 70px;
  height: 70px;
  z-index: 10000;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #0a69bd;
  width: 35%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

.header .h-logo {
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 900px) {
  .header .h-logo {
    width: 50%;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .header {
    padding: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .header {
    padding: 24px;
  }
}

.header {
  z-index: 9997;
  position: fixed;
  top: 0;
  width: 100%;
  height: fit-content;
  left: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.header--unpinned {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

@media only screen and (max-width: 900px) {
  .header--pinned {
    background-color: inherit;
  }
}

.header--pinned #g-nav {
  display: block;
}

.h_contact,
.fix_contact {
  display: flex;
  align-items: center;
}

.h_contact a,
.fix_contact a {
  display: block;
  color: #fff;
  font-size: clamp(20px, 2vw, 20px);
}

.h_contact .mail,
.fix_contact .mail {
  padding: 24px;
  background-color: #0a69bd;
}

.h_contact .shop,
.fix_contact .shop {
  padding: 24px;
  background-color: #e52526;
}

.h_contact .tel,
.fix_contact .tel {
  color: #333333;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-right: 40px;
}

.h_contact .tel span,
.fix_contact .tel span {
  font-weight: 400;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .h_contact {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .h_contact {
    display: none;
  }
}

.fix_contact {
  display: none;
  z-index: 2;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .fix_contact {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

@media only screen and (max-width: 900px) {
  .fix_contact {
    display: flex;
    justify-content: flex-end;
  }
}

.mainWrap {
  position: relative;
  border: 1px solid #0a69bd;
  max-width: calc(100% - 150px);
  margin: auto;
  padding: 10%;
  height: 90vh;
  display: flex;
}

@media screen and (max-width: 640px) {
  .mainWrap {
    max-width: calc(100% - 80px);
    padding: 10% 5%;
  }
}

.mainWrap .mainText {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.mainWrap .mainText h1 {
  text-align: center;
}

.mainWrap .mainText h1 .big {
  display: block;
  line-height: 1.25em;
  font-family: 'Shippori Mincho', serif;
  font-size: clamp(32px, 7vw, 75px);
}

.mainWrap .mainText h1 .sub {
  display: block;
  margin-top: 4rem;
  font-size: 1.8rem;
  line-height: 2em;
}

.mainArea {
  text-align: center;
  margin: auto;
}

.subArea {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.subArea h1 {
  padding: 5rem 24px;
  font-size: clamp(40px, 5vw, 50px);
  line-height: 1em;
  font-family: 'Shippori Mincho', serif;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .subArea h1 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .subArea h1 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.subArea article > div {
  font-size: clamp(80px, 15vw, 150px);
  display: block;
  line-height: 1.5em;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #F2F7FB;
  position: relative;
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #333333;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .pankuzu {
    margin-top: 0;
  }
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

@media only screen and (max-width: 900px) {
  footer#global_footer {
    text-align: center;
  }
}

footer#global_footer .footer {
  display: flex;
  justify-content: space-between;
  padding: 100px 24px;
  position: relative;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer {
    display: block;
  }
}

footer#global_footer .footer .ftLogo {
  text-align: left;
  margin-bottom: 40px;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer .ftLogo {
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .ftLogo {
    text-align: center;
  }
}

footer#global_footer .ftNav {
  display: flex;
  flex-flow: row wrap;
  width: 442px;
}

@media screen and (max-width: 640px) {
  footer#global_footer .ftNav {
    width: 100%;
  }
}

footer#global_footer .ftNav li {
  text-align: left;
}

footer#global_footer .ftNav li .ftDropdown {
  display: flex;
  flex-wrap: wrap;
}

footer#global_footer .ftNav li a {
  display: inline-block;
  color: #333333;
  padding: 10px;
}

footer#global_footer .ftNav .red {
  position: relative;
}

footer#global_footer .ftNav .red::before {
  content: "";
  width: 100px;
  background-color: #e52526;
  height: 1px;
  position: absolute;
  left: -100px;
  top: 24px;
}

footer#global_footer .ftNav2 {
  margin-top: 50px;
}

footer#global_footer .ftNav2 li {
  text-align: right;
}

footer#global_footer .ftNav2 li a {
  color: #333333;
  font-size: 1.4rem;
}

footer#global_footer #copy {
  color: #333333 !important;
  font-size: 1.4rem;
  display: block;
  text-align: left;
  margin-top: 50px;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #333333 !important;
}

.title_01 h2 {
  font-size: clamp(32px, 5vw, 52px);
  line-height: 1.2em;
  font-family: 'Shippori Mincho', serif;
}

.dot-svg path {
  opacity: 0;
}

.news {
  max-height: 290px;
  overflow-y: auto;
  text-align: left;
}

.news dt,
.news dd {
  border: none !important;
}

@media screen and (max-width: 834px) {
  .news dt,
  .news dd {
    width: 100% !important;
  }
}

.news dt {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: #0a69bd;
}

.news dl {
  border-bottom: 1px solid #DFECF6;
  padding: 15px 0;
}

@media screen and (max-width: 834px) {
  .news dl {
    display: block !important;
  }
}

.text div {
  line-height: 2.5em;
}

.text div .talR {
  width: 100%;
  font-size: 2.6rem;
  text-align: right;
  font-family: 'Shippori Mincho', serif;
  display: block;
}

.cont_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .cont_01 {
    display: block;
  }
}

.cont_01 .flxL {
  width: 50%;
}

.cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_01 .flxR {
  width: 49%;
}

@media screen and (max-width: 834px) {
  .cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .cont_01 {
    display: block;
  }
  .cont_01 .flxR {
    width: 100%;
    margin-top: 200px;
  }
  .cont_01 .flxL {
    width: 100%;
  }
}

.cont_01 .flxR {
  padding: 0 5%;
}

.cont_01 .moveText {
  position: absolute;
  left: 0;
}

.cont_01::before {
  content: "";
  width: 81.25vw;
  height: 630px;
  background-color: #dfecf6;
  position: absolute;
  right: 0;
  top: -130px;
  z-index: -2;
}

.cont_02 .inner {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .cont_02 .inner {
    display: block;
  }
}

.cont_02 .inner .flxL {
  width: 50%;
}

.cont_02 .inner .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_02 .inner .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_02 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_02 .inner .flxR {
  width: 49%;
}

@media screen and (max-width: 834px) {
  .cont_02 .inner .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_02 .inner .title_01 h2 {
  font-size: 3.2rem;
}

.cont_03 {
  background-attachment: fixed;
  background-position: center;
}

.cont_03 .inner {
  max-width: 1200px;
}

.cont_03 .contBtm {
  background-color: #fff;
  padding: 100px 24px;
}

@media screen and (max-width: 834px) {
  .cont_03 {
    background-attachment: scroll;
  }
}

.flexWrap {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  position: relative;
}

.flex_01 {
  flex-wrap: inherit !important;
  align-items: flex-end;
}

@media only screen and (max-width: 900px) {
  .flex_01 {
    flex-wrap: wrap !important;
  }
}

.flex_01 .box {
  width: 48%;
  overflow: visible !important;
}

@media only screen and (max-width: 900px) {
  .flex_01 .box {
    width: 100% !important;
  }
}

.flex_01 .box img {
  width: 100% !important;
}

.flex_01 .box:nth-of-type(2) {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 900px) {
  .flex_01 .box:nth-of-type(2) {
    margin-top: 200px;
    padding: 30px;
  }
}

.flex_01 .box:nth-of-type(2)::before {
  content: "";
  width: 910px;
  height: 340px;
  position: absolute;
  left: -20%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  z-index: -1;
}

@media only screen and (max-width: 900px) {
  .flex_01 .box:nth-of-type(2)::before {
    width: 100%;
    height: 300px;
    left: 0;
  }
}

.flex_01 .box .number {
  width: fit-content;
  position: absolute;
  right: -110px;
  top: -110px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: clamp(80px, 10vw, 100px);
  color: #0a69bd;
  line-height: 1em;
}

@media only screen and (min-width: 835px) and (max-width: 1500px) {
  .flex_01 .box .number {
    right: 0;
  }
}

@media only screen and (max-width: 900px) {
  .flex_01 .box .number {
    right: 0;
    top: -80px;
  }
}

.flex_01 .box h2 {
  font-size: clamp(24px, 4vw, 42px);
  line-height: 1.2em;
  font-family: 'Shippori Mincho', serif;
  margin-bottom: 50px;
}

.flex_01::before {
  content: "";
  width: 440px;
  height: 440px;
  background-color: #f3f8fc;
  position: absolute;
  right: 0;
  bottom: -200px;
}

.flex_02 {
  flex-wrap: inherit !important;
  align-items: flex-end;
  flex-direction: row-reverse !important;
}

@media only screen and (max-width: 900px) {
  .flex_02 {
    flex-wrap: wrap !important;
  }
}

.flex_02 .box {
  width: 48%;
  overflow: visible !important;
}

@media only screen and (max-width: 900px) {
  .flex_02 .box {
    width: 100% !important;
  }
}

.flex_02 .box img {
  width: 100% !important;
}

.flex_02 .box:nth-of-type(2) {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 900px) {
  .flex_02 .box:nth-of-type(2) {
    margin-top: 200px;
    padding: 30px;
  }
}

.flex_02 .box:nth-of-type(2)::before {
  content: "";
  width: 910px;
  height: 340px;
  position: absolute;
  right: -20%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  z-index: -1;
}

@media only screen and (max-width: 900px) {
  .flex_02 .box:nth-of-type(2)::before {
    width: 100%;
    height: 300px;
    right: 0;
  }
}

.flex_02 .box .number {
  width: fit-content;
  position: absolute;
  left: -110px;
  top: -110px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: clamp(80px, 10vw, 100px);
  color: #0a69bd;
  line-height: 1em;
}

@media only screen and (min-width: 835px) and (max-width: 1500px) {
  .flex_02 .box .number {
    left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .flex_02 .box .number {
    left: 0;
    top: -80px;
  }
}

.flex_02 .box h2 {
  font-size: clamp(24px, 4vw, 42px);
  line-height: 1.2em;
  font-family: 'Shippori Mincho', serif;
  margin-bottom: 50px;
}

.flex_02::before {
  content: "";
  width: 440px;
  height: 440px;
  background-color: #f3f8fc;
  position: absolute;
  left: 0;
  bottom: -200px;
}

.flex_contact {
  justify-content: center;
  align-items: center;
}

.flex_contact .box {
  text-align: center;
}

.flex_contact .box:first-of-type {
  margin-right: 40px;
}

@media screen and (max-width: 640px) {
  .flex_contact .box:first-of-type {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.flex_contact .box .time {
  color: #ccc;
}

.flex_contact .box i {
  margin-right: 8px;
}

.flex_contact .box a {
  display: block;
  color: #fff;
  font-size: clamp(20px, 2vw, 20px);
}

.flex_contact .box .mail {
  padding: 24px;
  background-color: #0a69bd;
}

.flex_contact .box .tel {
  color: #333333;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.flex_contact .box .tel span {
  font-weight: 400;
}

.top-sns {
  background: linear-gradient(#fff 50%, #f3f8fc 50%);
}

.top-sns div.sns_list {
  flex-direction: row;
  border: none;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top-sns div.sns_list > div {
  width: calc((100% - 100px)/5);
  flex-direction: column;
  border: none;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .top-sns div.sns_list > div {
    width: calc((100% - 48px)/4);
    margin: 0 16px 16px 0;
  }
}

@media screen and (max-width: 640px) {
  .top-sns div.sns_list > div {
    width: calc(50% - 8px);
  }
}

.top-sns div.sns_list > div:nth-child(4n) {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .top-sns div.sns_list > div:nth-child(2n) {
    margin-right: 0;
  }
}

.top-sns div.sns_list > div div.sns_photo {
  width: 100%;
  padding: 0;
}

.top-sns div.sns_list > div div.sns_photo a {
  display: block;
  padding: 0 0 100%;
  position: relative;
  overflow: hidden;
}

.top-sns div.sns_list > div div.sns_photo a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

.top-sns div.sns_list > div div.sns_text {
  display: none;
}

.btn, .btn2 {
  width: fit-content;
  margin-right: auto;
}

.btn a, .btn2 a {
  /*線の基点とするためrelativeを指定*/
  position: relative;
  /*ボタンの形状*/
  color: #fff;
  padding: 20px;
  display: inline-block;
  min-width: 300px;
  background-color: #0a69bd;
  line-height: 1em;
}

.btn a:hover, .btn2 a:hover {
  background-color: #e52526;
}

.btn a::before, .btn2 a::before {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}

@media screen and (max-width: 640px) {
  .btn a, .btn2 a {
    width: 100%;
  }
}

.btn2 {
  margin: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

.moveText_trigger {
  opacity: 0;
}

.moveText_trigger svg {
  width: 100%;
}

.moveText_03 svg {
  stroke-width: 1px;
  stroke-dasharray: 910;
  animation: demo_kururi ease 4s;
  stroke-opacity: .4;
}

.moveText_03 {
  opacity: 1;
}

@keyframes demo_kururi {
  from {
    stroke-dashoffset: 910;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.moveText_02 {
  animation: loop 30s linear infinite;
  font-size: clamp(100px, 23vw, 230px);
  position: relative;
}

.moveText_02 ul {
  display: flex;
}

.moveText_02 ul li {
  font-family: 'Poppins', sans-serif;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.6);
  overflow: inherit !important;
  color: transparent;
  font-weight: bold;
  white-space: nowrap;
  line-height: 1em;
  float: none !important;
  margin-right: 50px;
}

@keyframes loop {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-300%);
  }
}

#builingual {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1;
  padding: 5px 10px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important;
}

.language {
  color: #333 !important;
  float: none !important;
  padding: 0 !important;
  font-family: 'Poppins', sans-serif;
}

.switch {
  float: none !important;
  width: 33px;
  margin-left: 5px !important;
  display: block !important;
}

.switch input[type="checkbox"] {
  display: none;
}

.switch label {
  height: 100%;
  display: block;
  box-sizing: border-box;
  padding: 5px 0 8px 0;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(136, 136, 136, 0.4);
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s;
}

div#builingual div.language select {
  margin: 0 !important;
  margin-left: 5px !important;
  font-family: 'Poppins', sans-serif;
}

/* ON */
.switch label span:after {
  content: "OFF";
  font-size: 10px;
  font-weight: 500;
  color: #aaa;
  font-family: 'Poppins', sans-serif;
}

.switch #cmn-toggle-1:checked + label {
  background-color: #0a69bd;
  box-shadow: none;
}

.switch #cmn-toggle-1:checked + label span:after {
  content: "ON";
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 834px) {
  #builingual select {
    padding: 4px 2px !important;
  }
}

@media screen and (max-width: 640px) {
  #builingual {
    top: auto;
    bottom: 0;
    right: 0;
    padding: 5px !important;
    background: rgba(255, 255, 255, 0.9) !important;
  }
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.pager {
  display: flex;
}

.shopPage #contents_wrap {
  margin-top: 100px;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.items {
  justify-content: space-between;
  margin-left: 0 !important;
}

@media screen and (max-width: 640px) {
  .items {
    display: block !important;
  }
}

.items article {
  width: 32% !important;
  margin-left: 0 !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .items article {
    width: 48% !important;
  }
}

@media screen and (max-width: 640px) {
  .items article {
    width: 100% !important;
  }
}

.items article .photo {
  width: 100% !important;
}

.items article .name {
  color: #0a69bd !important;
}

.items article .price {
  color: #333333 !important;
}

.items article button {
  border-radius: 0 !important;
  background: #0a69bd !important;
  color: #fff !important;
  border: none !important;
  text-indent: 0 !important;
}

div.items > article a.photo {
  height: 380px !important;
}

.shopPage div.items > article .thumb {
  z-index: 2;
  position: relative;
}

.shopPage div.items > article a {
  text-decoration: none;
}

#main #col_side1 {
  display: none;
}

.shopPage #main #col_side1 {
  display: block;
  padding-right: 24px;
}

@media screen and (max-width: 834px) {
  .shopPage #main #col_side1 {
    width: 100% !important;
  }
}

.view_cart a {
  background-color: #0a69bd;
  display: block;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.view_cart a i {
  margin-right: 10px;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1420px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1420px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1420px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1420px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1420px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1420px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: clamp(32px, 4vw, 42px);
  line-height: 1em;
  font-weight: bold;
  position: relative;
  padding: 20px 10px 20px 2.5rem;
  background-color: #f7f7f7;
  border-bottom: 4px solid #333;
}

.local_title_01 h2::before {
  content: url(../images/about_runpoke/runpoke_icon.png);
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_01 h3 {
  font-size: clamp(24px, 3vw, 32px);
  font-weight: bold;
  margin-bottom: 40px;
  line-height: 1.25em;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL, .img .imgR, .img .imgL {
    max-width: 100% !important;
  }
}

.local_cont_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  align-items: flex-start;
}

@media screen and (max-width: 834px) {
  .local_cont_01 {
    display: block;
  }
}

.local_cont_01 .flxL {
  width: 30%;
}

.local_cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .flxR {
  width: 68%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .local_cont_01 {
    display: block;
  }
  .local_cont_01 .flxR {
    width: 100%;
    margin-top: 200px;
  }
  .local_cont_01 .flxL {
    width: 100%;
  }
}

.local_cont_01 .flxR {
  padding: 0 5%;
}

.local_cont_01 .moveText {
  position: absolute;
  left: 0;
}

.local_cont_01::before {
  content: "";
  width: 81.25vw;
  height: 630px;
  background-color: #F2F7FB;
  position: absolute;
  right: 0;
  top: -130px;
  z-index: -2;
}

.dl_01 dt {
  border: none !important;
  padding: 25px !important;
  font-weight: bold;
  color: #0a69bd;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    width: 100% !important;
  }
}

.dl_01 dd {
  border: none !important;
  padding: 25px !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

.dl_01 dl {
  border-bottom: 1px solid #DFECF6;
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.dl_02 dt {
  border: none !important;
  padding: 20px !important;
  font-weight: bold;
  font-size: 7rem;
  line-height: 1.2em;
  font-family: 'Poppins', sans-serif;
  color: #0a69bd;
}

@media screen and (max-width: 834px) {
  .dl_02 dt {
    width: 100% !important;
  }
}

.dl_02 dd {
  border: none !important;
  padding: 20px !important;
}

@media screen and (max-width: 834px) {
  .dl_02 dd {
    width: 100% !important;
  }
}

.dl_02 dl {
  border-bottom: 1px solid #e1e1e1;
}

@media screen and (max-width: 834px) {
  .dl_02 dl {
    display: block !important;
  }
}

.local_flex_01 {
  justify-content: space-between;
  flex-wrap: inherit !important;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_flex_01 {
    flex-wrap: wrap !important;
  }
}

.local_flex_01 .box {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .local_flex_01 .box {
    width: 100%;
  }
}

.local_flex_01 .box h3 {
  font-size: clamp(24px, 3vw, 32px);
  font-weight: bold;
  margin-bottom: 40px;
  line-height: 1.25em;
}

.flex_img_01 {
  justify-content: space-between;
}

.flex_img_01 .box {
  margin-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .flex_img_01 .box {
    width: 100% !important;
  }
}

.flex_bnr {
  justify-content: space-between;
}

.flex_bnr .box {
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .flex_bnr .box {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

.bigBtn a {
  background-color: #0a69bd;
  padding: 50px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: clamp(32px, 4vw, 48px);
  line-height: 1em;
  display: block;
  position: relative;
}

.bigBtn a .ja {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  display: block;
}

.bigBtn a:hover {
  background-color: #e52526;
}

.bigBtn a::before {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}

.list_01 li {
  font-size: 2rem;
  position: relative;
  padding: 10px 10px 10px 3.5rem;
  margin-bottom: 20px;
  border-bottom: #e1e1e1 dashed 2px;
}

.list_01 li:before {
  font-family: "Font Awesome 5 pro";
  content: "\f14a";
  color: #e52526;
  position: absolute;
  left: 5px;
  top: 50%;
  font-size: 2.2rem;
  font-weight: bold;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.form {
  border-radius: 8px;
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #e7e7e7 !important;
  padding: 15px 0;
}

.form dl.file dt {
  background-color: #f7f7f7 !important;
}

div.error {
  margin-top: 200px !important;
}

.policy_cont p {
  margin-bottom: 10px;
}

.policy_cont h2 {
  font-size: 1.2em;
  border-bottom: 1px solid #0a69bd;
  padding-bottom: 8px;
  font-weight: bold;
  margin: 50px 0 20px 0;
}

.policy_cont .madoguchi {
  border: #DFECF6 1px solid;
  padding: 15px;
  margin-top: 30px;
  display: inline-block;
}

.title {
  display: none;
}

.confirm {
  margin-top: 230px !important;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.message {
  margin-top: 280px !important;
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeupTrigger {
  opacity: 0;
}

.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
